@import 'fonts';
@import 'variables';

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: 'Nunito-Medium', sans-serif;
  position: relative;
}

table {
  thead {
    tr {
      position: sticky;
      top: 0;
      background: white;
      z-index: 5000;

      th {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
  }
}

main {
  padding-bottom: 20px;
}

.buoy-settings-page__card-body__edit-button {
  padding: 10px;
}

.minWidth120 {
  min-width: 300px !important;
}

.marginAuto {
  margin: 0 auto;
}

.pagination {
  margin-bottom: 0;
}

.leaflet-bottom.leaflet-right {
  display: none;
}

.mh-200px {
  max-height: 200px;
  overflow: auto;
}

.mh-300px {
  max-height: 300px;
  overflow: auto;
}

.app {
  position: relative;
  height: 100vh;
}

.mb-1 {
  margin-bottom: 3px;
}

.flex-start {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.flex-end {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.custom-marker-icon-style {
  margin-top: -15px !important;
  margin-left: -8px !important;

  &.filled {
    background-color: $main-blue;
    padding: 1px;
    border-radius: 5px;
  }
}

.d-flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-1 {
  flex: 1 1 auto;
}

.align-items-center {
  align-items: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.d-none-sm {
  @media (max-width: 767px) {
    display: none;
  }
}

.d-none-md {
  @media (min-width: 768px) {
    display: none;
  }
}

.pointer {
  cursor: pointer;
}

.mv-10px {
  margin-top: 10px;
  margin-bottom: 10px;
}

.rightHeight {
  text-align: right;
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  height: 46px;
}

.map-page__map-container__left-control-scale-icon, .map-page__map-container__right-control-scale-icon {
  display: none;
}

.fs-12px {
  font-size: 12px !important;
}

.ph-10px {
  padding: 0 10px 0 10px;
}

.cellsP-2 {
  td {
    padding: 5px;
    width: auto;
    max-width: 310px;
    text-align: center;

    &:first-child {
      padding: 5px 5px 5px 0;
      text-align: left;
    }
  }
}

.hist_block {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: auto;

  tr {
    &:hover {
      background-color: $dark-shadow-color;
    }
  }
}

.overflow-auto {
  overflow: auto;
}

.max-width-70px {
  max-width: 70px !important;
  width: 70px !important;
}

.inputModal {
  position: absolute;
  top: 36px;
  right: 5px;
  background: $clear-white;
  border: solid 1px rgb(223, 223, 223);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 5001;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;

  table {
    th {
      padding: 3px;
      border: solid 1px rgb(223, 223, 223);
      font-weight: 600;
    }

    td {
      text-align: center;
      cursor: pointer;
      padding: 3px;
      border: solid 1px rgb(223, 223, 223);

      &.today {
        background: $main-blue;
        color: $clear-white;
      }

      &.current {
        border: solid 2px $main-blue;
        padding: 2px;
      }
    }
  }
}

.dateBlock {
  .inputModal {
    display: none;
  }

  &.focused {
    .inputModal {
      display: flex;
    }
  }
}

@media print {
  @page {
    size: A4 landscape;
    margin: 10%;
  }

  @media print and (width: 21cm) and (height: 29.7cm) {
    @page {
      margin: 3cm;
    }
  }

  @media print and (width: 8.5in) and (height: 11in) {
    @page {
      margin: 1in;
    }
  }

  .noPrint {
    display: none;
  }
}

@media print {
  @page {
    size: A3 landscape;
    margin: 0;
    page-break-before: always;
    overflow: visible;
  }
  .leaflet-control-geosearch.leaflet-geosearch-bar, .leaflet-control-zoom.leaflet-bar.leaflet-control, .map-page__map-container__print-button {
    display: none !important;
  }
}

.redText {
  color: $red !important;
}

@import 'bootstrap/dist/css/bootstrap.min.css';
